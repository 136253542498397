




















import HealthProfessionFieldForm from '@/components/healthProfessionField/HealthProfessionFieldForm.vue';
import HealthProfessionFieldUtils, {
  HealthProfessionField,
} from '@/utils/HealthProfessionFieldUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { HealthProfessionFieldForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      errors?: any[];
    };
    item?: Partial<HealthProfessionField>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    this.item =
      HealthProfessionFieldUtils.healthProfessionField.generateDefault({
        deployment: this.$route.query.deployment === 'true' || false,
      });
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await HealthProfessionFieldUtils.api.create(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.create', [
              this.$tc('healthProfessionField.label'),
            ]),
            type: 'success',
          });
          this.$router.replace({ name: 'health-profession-field-list' });
        } catch (error) {
          this.$toast.push({
            text: this.$t('error.create', [
              this.$tc('healthProfessionField.label'),
            ]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
